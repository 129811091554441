class NewsFilter
{
  constructor() {
    this.$filter = document.getElementById('news-filter')

    // ensure filter dom elements exists on current page
    if (this.$filter && (this.$filter !== undefined)) {
      this.$newsList = document.getElementById('news-list')
      this.articles = []

      this.$tags = this.$filter.querySelectorAll('.filter-tag')

      this.initArticles(this.$newsList.querySelectorAll(':scope > .news'))
      this.registerListener()

      let activeHash = window.location.hash.substr(1)

      if (activeHash.length > 0)
        this.filter(activeHash)
    }
  }

  initArticles($articles) {
    $articles.forEach(($article) => {
      this.articles.push({
        $el: $article,
        tags: $article.getAttribute('data-tags').split(',')
      })
    })

  }

  registerListener() {
    this.$tags.forEach(($tag) => {
      $tag.addEventListener('click', this.clickHandler.bind(this))
    })
  }

  clickHandler(e) {
    e.preventDefault

    let $el = e.target
    let hash = $el.href.split('#')[1]

    if ($el.classList.contains('active')) {
      $el.classList.remove('active')

      this.reset()
    } else {
      // remove active class from all filters
      this.$tags.forEach($tag => { $tag.classList.remove('active') })

      // add active class to current filter
      $el.classList.add('active')

      this.filter(hash)
    }
  }

  filter(hash) {
    this.articles.forEach((article) => {
      if (article.tags.includes(hash)) {
        article.$el.classList.remove('filtered')
      } else {
        article.$el.classList.add('filtered')
      }
    })
  }

  reset() {
    this.articles.forEach((article) => {
      article.$el.classList.remove('filtered')
    })
  }
}
