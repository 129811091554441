class Navigation {
  constructor($container) {
    this.$container = $container
    this.$burger = $container.querySelector('.navigation__hamburger')
    this.$menu = $container.querySelector(':scope > .menu')
    this.dropDowns = this.$menu.querySelectorAll('.dropdown')

    this.registerListener()
  }

  registerListener() {
    let me = this

    this.$container.querySelector('.site__navigation__button').addEventListener('click', e => {
      me.$burger.classList.toggle('opened')
      me.$menu.classList.toggle('menu-open')
    })


    this.dropDowns.forEach((item) => {
      item.onmouseover = item.onmouseout = item.onclick = function (e) {

        // if mobile
        if(window.innerWidth <= 768) {
          // on click
          if (e.type === 'click') {
            var parent = e.target.parentElement

            if (parent.classList.contains('dropdown')) {
              e.preventDefault();

              if(item.classList.contains('dropdown-open')) {
                // close clicked item
                item.classList.remove('dropdown-open')
              } else {
                // close all other opened items
                me.dropDowns.forEach($el => $el.classList.remove('dropdown-open'))
                // open clicked item
                item.classList.add('dropdown-open')
              }
            }
          }
        } else {
          // on mouse enter
          if (e.type === 'mouseover') {
            item.classList.add('dropdown-open')
          }
          // on mouse out
          else if (e.type === 'mouseout') {
            item.classList.remove('dropdown-open')
          }
        }
      }
    })
  }
}
