document.addEventListener("DOMContentLoaded", function () {
  let $siteHeader = document.getElementById('site__header');
  let $siteNavigation = document.getElementById('site__navigation');

  window.addEventListener('scroll', function (e) {
    if (window.pageYOffset > 0) {
      $siteHeader.classList.add('is-stuck')
    } else {
      $siteHeader.classList.remove('is-stuck')
    }
  });

  new Navigation($siteNavigation);

  let galleries = document.querySelectorAll(".gallery_image")

  if (galleries.length > 0) {
    new LuminousGallery(galleries)
  }

  if (document.querySelectorAll('.slider').length > 0) {
    let slider = tns({
      container: '.slider',
      items: 1,
      slideBy: 'page',
      autoplay: true,
      autoplayButtonOutput: false,
      nav: false,
      controlsContainer: '.slider-controls',
      speed: 500,
      autoplayTimeout: 10000
      // nextButton: '.next-slide'
    });
  }

  new NewsFilter()

});
